import apiClient from "./intoCultureApiClient";
import AuthorizationError from "../errors/AuthorizationError";
import IntoCultureApiClientError from "../errors/IntoCultureApiClientError";
import OrderAwaitingPaymentError from "../errors/OrderAwaitingPaymentError";
import OrderCanceledError from "../errors/OrderCanceledError";
import OrderCreateError from "../errors/OrderCreateError";
import ProductNotFoundError from "../errors/ProductNotFoundError";

import {
  LOCAL_STORAGE_ORDER,
  LOCAL_STORAGE_ORDER_ID,
  LOCAL_STORAGE_REDIRECT_URL,
  LOCAL_SESSION_ORDER,
} from "../constants/localStorage";

const orderService = () => {
  const ORDER_STATUS_NEW = "new";
  const ORDER_STATUS_CANCELED = "canceled";
  const ORDER_STATUS_REFUNDED = "refunded";
  const ORDER_STATUS_AWAITING_PAYMENT = "in_progress";
  const ORDER_STATUS_PAYMENT_SUCCESS = "paid";

  let order = {
    productSlug: null,
    tickets: [],
  };

  let orderRaw = null;

  const clearOrderData = () => {
    localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
    localStorage.removeItem(LOCAL_STORAGE_ORDER);
  };

  const getOrderRaw = () => orderRaw;

  // TODO: confusing name apiClient.getOrder vs orderService.getOrder
  const getOrder = () => order;

  const setOrderRaw = (rawData) => {
    orderRaw = rawData;
  };

  const setOrder = (
    { productSlug, tickets, date, timeslot, selectedTickets },
    rawData = null
  ) => {
    order = {
      ...order,
      productSlug,
      tickets,
      selectedTickets,
      ...(date && { date }),
      ...(timeslot && { timeslot }),
    };

    setOrderRaw(rawData);

    return order;
  };

  const persistOrder = (persistOrder) => {
    if (persistOrder) {
      setOrder(persistOrder);
    }

    localStorage.setItem(LOCAL_STORAGE_ORDER, JSON.stringify(order));

    return order;
  };

  const orderQuantity = () => {
    return order.tickets.reduce((acc, ticket) => {
      return acc + ticket.amount;
    }, 0);
  };

  const loadOrder = ({ slug, variants }) => {
    const orderString = localStorage.getItem(LOCAL_STORAGE_ORDER);

    if (!orderString) {
      // no local order found
      return persistOrder({ productSlug: slug, tickets: variants });
    }

    const localOrder = JSON.parse(orderString);

    if (slug && localOrder.productSlug === slug) {
      // local order is indeed the current product
      setOrder(localOrder);
    } else {
      // local order belongs to a previously viewed product
      persistOrder({ productSlug: slug, tickets: variants });
    }

    return order;
  };

  const doPayment = async ({ order, returnTo }) => {
    try {
      const {
        order_id: orderId,
        redirect_url: paymentUrl,
      } = await apiClient.postOrder(order);

      localStorage.setItem(LOCAL_STORAGE_ORDER_ID, orderId);
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, returnTo);

      // TODO: Until proper errors are thrown by apiClient, this is the way
      if (!paymentUrl) {
        return false;
      }

      location.assign(paymentUrl);

      return true;
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status < 500
      ) {
        throw new OrderCreateError(err.response.data.message);
      }

      throw err;
    }
  };

  const doPaymentRedirect = () => {
    const orderId = localStorage.getItem(LOCAL_STORAGE_ORDER_ID);
    const redirectBaseUrl = localStorage.getItem(LOCAL_STORAGE_REDIRECT_URL);
    const redirectAppend = "/checkout-success/";

    if (!orderId || !redirectBaseUrl) {
      window.location.assign("/");
    }

    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_URL);
    window.location.assign(redirectBaseUrl + redirectAppend + orderId);
  };

  const paymentConfirmByResponse = async (orderId, response) => {
    // TODO: Until proper errors are thrown by apiClient, this is the way
    // we have no clue why it's failing
    if (!response) {
      throw new IntoCultureApiClientError(`Unable to fetch order ${orderId}`);
    }

    const { status, product, lines } = response;

    if (!status) {
      // most likely the user is not authorized
      throw new AuthorizationError();
    }

    if (
      status === ORDER_STATUS_AWAITING_PAYMENT ||
      status === ORDER_STATUS_NEW
    ) {
      throw new OrderAwaitingPaymentError(
        `Awaiting payment for order ${orderId}`
      );
    } else if (
      status === ORDER_STATUS_REFUNDED ||
      status === ORDER_STATUS_CANCELED
    ) {
      throw new OrderCanceledError(`Couldn't find order ${orderId}`);
    }

    setOrder(
      {
        productSlug: product.slug,
        date: lines[0].date,
        timeslot: lines[0].timeslot,
      },
      response
    );

    return status === ORDER_STATUS_PAYMENT_SUCCESS;
  };

  const paymentConfirm = async (orderId) => {
    const response = await apiClient.getOrder(orderId);
    return await paymentConfirmByResponse(orderId, response);
  };

  const doClickOutRedirect = async (product) => {
    try {
      const result = await apiClient.getClickOutProductUrl(product);
      let clickOutProductUrl = result.redirect_url;

      // @todo: change to check on http response header 401
      if (!clickOutProductUrl) {
        throw new IntoCultureApiClientError(
          "Could not get clickout product url."
        );
      }

      sessionStorage.setItem(LOCAL_SESSION_ORDER, JSON.stringify(result));

      if (product.redirect_type === "internal") {
        const slugProducts = window.dataStatic.slug.products;
        clickOutProductUrl = "/" + slugProducts + clickOutProductUrl;
      }
      location.assign(clickOutProductUrl);
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status < 500
      ) {
        if (err.response.data) {
          throw new ProductNotFoundError(err.response.data.message);
        } else {
          throw new ProductNotFoundError(err.response.error.message);
        }
      }
      throw err;
    }
  };

  const getTicketsUrl = async (orderId) => {
    const response = await apiClient.getTickets(orderId);

    return response;
  };

  const requiresActiveMembership = (product) => {
    if (product && product.variants) {
      for (const variant of product.variants) {
        if (variant.requires_active_membership === true) {
          return true;
        }
      }
    }
    return false;
  };

  return {
    clearOrderData,
    paymentConfirm,
    paymentConfirmByResponse,
    getOrderRaw,
    getOrder,
    setOrder,
    loadOrder,
    persistOrder,
    orderQuantity,
    doPayment,
    doPaymentRedirect,
    getTicketsUrl,
    doClickOutRedirect,
    requiresActiveMembership,
  };
};

export default orderService();
