<template>
  <main v-if="!isLoading && paymentConfirmed">
    <div class="checkout__container">
      <div v-if="email" class="checkout__main-content is-narrow--centered">
        <checkout-review-order-card
          :product="product"
          :order="order"
        ></checkout-review-order-card>

        <div class="checkout__content-block">
          <p>
            {{
              "You will receive the following e-tickets in a couple of minutes on"
                | t
            }}:
            {{ email }}
          </p>

          <button
            :class="
              waitingOnHandout
                ? 'button--primary is-full-width is-disabled'
                : 'button--primary is-full-width'
            "
            :disabled="waitingOnHandout"
            @click="downloadTickets"
          >
            <i class="far fa-file-download"></i>
            {{
              waitingOnHandout
                ? "Jouw e-ticket wordt aangemaakt"
                : "Download your e-ticket" | t
            }}
          </button>

          <div>
            <a href="/">
              {{ "Go back to the homepage" | t }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import apiEndpoints from "../../../services/intoCultureApiClientEndpoints";
import EventBus from "../../../event-bus";
import order from "../../../services/orderService";
import OrderAwaitingPaymentError from "../../../errors/OrderAwaitingPaymentError";
import OrderCanceledError from "../../../errors/OrderCanceledError";
import user from "../../../services/userServices/userService";
import AuthorizationError from "../../../errors/AuthorizationError";
import UserAuthenticationError from "../../../errors/UserAuthenticationError";
import gtmEventService from "../../../services/gtmEventService";
import apiClient from "../../../services/intoCultureApiClient";

export default {
  name: "CheckoutPageOrderSuccess",
  props: {
    orderId: {
      required: true,
      type: Number,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      paymentConfirmed: false,
      email: null,
      order: {},
      waitingOnHandout: true,
    };
  },
  computed: {
    header() {
      return {
        title: this.$options.filters.t("Your order was successful") + "!",
      };
    },
  },
  async mounted() {
    try {
      this.email = await user.getUser(); // gets email
      const response = await apiClient.getOrder(this.orderId);
      this.paymentConfirmed = await order.paymentConfirmByResponse(
        this.orderId,
        response
      );
      await this.checkHandoutComplete(response);

      order.clearOrderData();

      EventBus.$emit("mountedCheckoutStep", { header: this.header });
    } catch (err) {
      if (err instanceof OrderAwaitingPaymentError) {
        EventBus.$emit("failedCheckoutStep", {
          header: {
            title: this.$options.filters.t(
              "Unfortunately the payment hasn't been registered (yet)"
            ),
          },
          errorMessage: this.$options.filters.t(
            "Please try to refresh the page in a few moments"
          ),
        });
      } else if (err instanceof OrderCanceledError) {
        EventBus.$emit("failedCheckoutStep", {
          header: {
            title: this.$options.filters.t(
              "Unfortunately this order has been canceled"
            ),
          },
          errorMessage: this.$options.filters.t(
            "We haven't been able to find it"
          ),
        });
      } else if (
        err instanceof AuthorizationError ||
        err instanceof UserAuthenticationError
      ) {
        EventBus.$emit("failedCheckoutStep", {
          header: {
            title: this.$options.filters.t(
              "Unfortunately you've been logged out"
            ),
          },
          errorMessage: "Please try to login again",
          cta: { type: "login" },
        });
      } else {
        throw err;
      }
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async downloadTickets() {
      const params = {
        order: this.orderId,
      };

      location.href = apiEndpoints.tickets(params);
    },
    async checkHandoutComplete(response) {
      this.waitingOnHandout = response.lines.some(
        (line) => !(line.code && line.code_type)
      );
      if (this.waitingOnHandout) {
        setTimeout(async () => {
          const response = await apiClient.getOrder(this.orderId);
          await this.checkHandoutComplete(response);
        }, 1000);
      } else {
        gtmEventService.init(order.getOrderRaw()).push();
      }
    },
  },
};
</script>
